import { Box, Grid, Typography } from '@mui/material'
import { ProgressLoaders } from 'components'
import QuickLinks from 'components/layouts/AuthorizedLayout/component/QuickLinks'
import { USER_TOKEN } from 'config'
import { useAuth } from 'context'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useLocalStorage } from 'utils'
import { type BillingData, type BillingSegmentData, type SQS, type UsageData, type UsageResponse } from 'utils/hooks/types'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import {
  Bill,
  NumberStats,
  PaperlessBilling,
  PowerCompare,
  PowerUsage,
  Savings
} from './components'
import NemModal from './components/NemModal'

const IS_FIRST_LOGIN = 'IS_FIRST_LOGIN'

export const Dashboard: React.FC = () => {
  const { account, currentAccount, isAdmin, getUsage, premiseId, userEmail, usage, setBilling } = useAuth()
  const [authToken] = useLocalStorage(USER_TOKEN, '')
  const [dashboardStats, setDashboardStats] = useState<IDashboardStats | null>(null)
  const [dashboardUsage, setDashboardUsage] = useState<UsageData | null>(null)
  const isMobile = useMobileBreakpoints()
  const [prevAccountId, setPrevAccountId] = useState<string>('')
  const [isFirstLogin, setIsFirstLogin] = useLocalStorage(IS_FIRST_LOGIN, '')
  const [isOpen, setIsOpen] = useState(false)

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const emptyDashboardData = () => {
    setDashboardUsage({
      status: '',
      premiseId: '',
      frequency: '',
      usageHistory: []
    })
    setDashboardStats({
      usageThisMonth: 0,
      highestThisYear: 0,
      monthlyAverage: 0,
      totalUsage: 0
    })
  }

  useEffect(() => {
    setDashboardUsage(null)
    setDashboardStats(null)
    const fetchDashboardUsage = async (): Promise<void> => {
      const usageStatus = usage?.usage?.status ?? false
      if (account?.perAcctList?.length === 0 || ((usage?.usage?.usageHistory?.length) == null)) emptyDashboardData()
      if ((prevAccountId === currentAccount?.accountId || currentAccount?.accountId === account?.myAccount?.accountId) && usage !== null && usageStatus === false) {
        handleDashboardUsageData(usage)
      } else {
        setBilling(null)
        const dashboardUsage = await getUsage({
          AccessToken: authToken,
          premiseId: currentAccount?.premiseId ?? premiseId,
          accountId: currentAccount?.accountId ?? account?.myAccount?.accountId,
          frequency: 'M',
          startDate: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD'),
          service: '',
          admin: isAdmin,
          email: userEmail
        })
        if (dashboardUsage?.usage?.usageHistory != null) handleDashboardUsageData(dashboardUsage)
      }
      setPrevAccountId((currentAccount?.accountId ?? account?.myAccount?.accountId) ?? '')
    }
    void fetchDashboardUsage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount, userEmail, isAdmin, authToken])

  useEffect(() => {
    if (isFirstLogin.length === 0 && account?.myAccount?.isNEM === true) {
      setIsOpen(true)
      setIsFirstLogin('true')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usage?.usage])

  const handleDashboardUsageData = (usageRes: UsageResponse): void => {
    if ((usageRes?.usage?.billData) != null) {
      const netValues: number[] = []

      usageRes.usage.billData.forEach((bill: BillingData) => {
        bill.billSegmentData.forEach((segment: BillingSegmentData) => {
          segment?.sq?.sqs.forEach((sqs: SQS) => {
            if (sqs.sqi === 'NET') {
              netValues.push(parseFloat(sqs.billSq ?? '0'))
            }
          })
        })
      })

      const usageThisMonth = netValues[netValues.length - 1] ?? 0
      const highestThisYear = Math.max(...netValues)
      const totalUsage = +(netValues.reduce((acc, value) => acc + value, 0).toFixed(2))
      const monthlyAverage = +(totalUsage / netValues.length).toFixed(2)
      setDashboardUsage(usageRes.usage)
      setDashboardStats({
        usageThisMonth: usageThisMonth ?? 0,
        highestThisYear,
        monthlyAverage,
        totalUsage
      })
    } else emptyDashboardData()
  }

  return (
    <>
      {isMobile && <Box pb={2}>
        <Typography fontSize={24} fontWeight={300}>Welcome back <b>{account?.myAccount?.personName}</b></Typography>
        <QuickLinks />
      </Box>}
      <NemModal open={isOpen} setIsOpen={setIsOpen} />
      <Grid container spacing={3}>
        <Grid item xs={12} xl={7}>
          <Bill />
        </Grid>
        <Grid item xs={12} xl={5}>
          {dashboardStats != null
            ? <NumberStats dashboardStats={dashboardStats} />
            : <ProgressLoaders height="100%" />}
        </Grid>
        <Grid item xs={12} xl={7}>
          {dashboardUsage != null
            ? <PowerUsage />
            : <ProgressLoaders height="100%" />}
        </Grid>
        <Grid item xs={12} xl={5}>
          <Savings />
        </Grid>
        <Grid item xs={12} xl={7}>
          {dashboardStats != null
            ? <PowerCompare dashboardStats={dashboardStats} />
            : <ProgressLoaders height="100%" />}
        </Grid>
        <Grid item xs={12} xl={5}>
          <PaperlessBilling />
        </Grid>
      </Grid >

    </>
  )
}

export interface IDashboardStats {
  usageThisMonth: number
  highestThisYear: number
  monthlyAverage: number
  totalUsage: number
}
